import React from 'react'

const Navbar = () => {
  return (
    <nav className='bg-[#845EC2] p-2 text-white text-[22px] font-bold'>
      <h1>Syncast</h1>
    </nav>
  )
}

export default Navbar